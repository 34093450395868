import ForgotPasswordComponent from "../../components/ForgotPassword";

const ForgotPassword = () => {
  return (
    <>
      <ForgotPasswordComponent />
    </>
  );
};

export default ForgotPassword;
