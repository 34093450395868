import RegistrationComponent from "../../components/Register";

const Registration = () => {
  return (
    <>
      <RegistrationComponent />
    </>
  );
};

export default Registration;
