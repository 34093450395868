import GettingStartedComponent from "../../components/GettingStarted";

const GettingStarted = () => {
  return (
    <>
      <GettingStartedComponent />
    </>
  );
};

export default GettingStarted;
